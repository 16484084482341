//UI Theme
export const violetTheme = {
  body: "#F4EEFC",
  text: "#430A58",
  highlight: "#D6BEF4",
  dark: "#21052C",
  secondaryText: "#875599",
  imageHighlight: "#9b5de5",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#763D8B",
  headerColor: "#9b5de577",
};

export const chosenTheme = violetTheme;
